<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-cart</v-icon>
      Control Orders

      <back-button />
    </h2>
    <hr class="my-5 header-divider" />
    <filters-box @fetch="fetchRecords({ page: 1 })" />
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="جاري تحميل البيانات"
      no-data-text="لا يوجد بيانات"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >

     

      <template v-slot:item.details="{ item }">
        <router-link
          :to="{
            name: 'orderDetails',
            params: { id: item.id, order_number: item.order_number },
          }"
        >
          <v-btn rounded color="teal darken-4" dark> Details </v-btn>
        </router-link>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
        total-visible="7"
        circle
        :value="parseInt(filters.page)"
        :length="totalPages"
        @input="$router.replace({ query: { ...$route.query, page: $event } })"
      ></v-pagination>
    </div>
  </div>
</template>


<script>
import FiltersBox from "./FiltersBox";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "orders",
  components: { FiltersBox },

  data: function () {
    return {
      items: [],
      drivers: [],
      order_status: [],
      drivers_assign: [],
      selected: null,
      table: {
        headers: [
          {
            value: "order_number",
            text: "Order Number",
          },
          {
            value: "name",
            text: "User Name",
          },
          {
            value: "order_statuses",
            text: "Order Status",
          },
          {
            value: "details",
            text: "Details",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {
    ...mapState("orders", ["records", "filters", "totalPages"]),
  },

  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }

        this.SET_FILTERS({ ...route.query });
        this.fetchRecords();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchRecords({ page: 1 });
  },

  methods: {
    ...mapMutations("orders", [
      "SHOW_RECORD_FORM",
      "SET_EDIT_MODE",
      "SET_RECORD_FORM",
      "SET_FILTERS",
    ]),

    fetchRecords(params) {
      this.table.loading = true;
      this.$store
        .dispatch("orders/fetchRecords", params)
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when rendering the data",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    
  },
};
</script>
<style scoped>
/* .maxWidth {
  width: 40%;
  text-align: center !important;
} */
</style>