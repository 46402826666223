<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3"><v-icon class="ml-1">mdi-filter</v-icon>Filters </h3>
    <v-row align="center">
      <!-- QUERY -->
      
      <!-- Q -->
      <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
          rounded
          outlined
          solo
          hide-details
          clearable
          label="Search by number or name. "
          v-model="filters.search"
        ></v-text-field>
      </v-col>
      
      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 } })"
        large
        color="primary">بحث</v-btn>
          <v-btn class="mr-2" @click="filters.active=1;$router.replace({ query: { ...filters, page: 1 } })" large color="primary"
          > 
          الطلبات المفعلة
        </v-btn>
         <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 } })"
          large
          color="primary"
          >عرض الكل
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'filters-box',


  data: function () {
    return {
      categoriesLoading: false,

      filters: {
        search: null,
      
      }
    }
  },


  created() {
  },


  computed: {
  },


  methods: {
    ...mapMutations('orders', ['SET_FILTERS']),
  }
}
</script>
